export default {
  blogerName: 'CHPOK',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/broklp',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCmMt8Ml9WFbtiVnGUGTDjZA/videos',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCOGHuxS-PS1KHIABFplOMBg',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@qsqldqdlw',
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/channel/UCNIlUZwGa5MTEAVOuIyue4A',
    }
  ],
  projects: [
    {
      name: 'lex',
      url: 'https://lex-irrs01.com/c3be7a050',
      gameTitle: 'на выбор: The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>225% + 400FS</strong><div>На первый депозит</div>',
    },
    {
      name: '1go',
      url: 'https://1go-irrs.com/ca6086a01',
      gameTitle: 'на выбор: Cleocatra, Gates of Olympus или The Dog House',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs.com/cb28fdd5f',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs.com/c8fb53572',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs.com/c4fd9f32a',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'rox',
      url: 'https://rox-nxoyfjmrn.com/c1081d5a6',
      gameTitle: 'Kali Rox (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://fresh-tmmpgchred.com/c53ada0d5',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://sol-egikvdtdev.com/ce601e803',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jtfr-mutlukir.com/cd4ae6cb8',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir23.com/c81958dbd',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs.com/c6d03835b',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/cbb4e96e8',
      gameTitle: 'Legzo Punk (BGaming)',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>CHPOK</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'CHPOK',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
